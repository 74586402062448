.kb-animation {
  animation: kb 10s ease-in-out infinite alternate;
}

@keyframes kb {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.1) translate(0%, 0%);
  }
}
